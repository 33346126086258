@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/anyconvcom__gothambold-webfont.woff2")
      format("woff2"),
    url("/assets/fonts/gotham/anyconvcom__gothambold-webfont.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/anyconvcom__gothambolditalic-webfont.woff2")
      format("woff2"),
    url("/assets/fonts/gotham/anyconvcom__gothambolditalic-webfont.woff")
      format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/anyconvcom__gothambook-webfont.woff2")
      format("woff2"),
    url("/assets/fonts/gotham/anyconvcom__gothambook-webfont.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/anyconvcom__gothambookitalic-webfont.woff2")
      format("woff2"),
    url("/assets/fonts/gotham/anyconvcom__gothambookitalic-webfont.woff")
      format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/anyconvcom__gothamlight-webfont.woff2")
      format("woff2"),
    url("/assets/fonts/gotham/anyconvcom__gothamlight-webfont.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/anyconvcom__gothamlightitalic-webfont.woff2")
      format("woff2"),
    url("/assets/fonts/gotham/anyconvcom__gothamlightitalic-webfont.woff")
      format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/anyconvcom__gothammedium-webfont.woff2")
      format("woff2"),
    url("/assets/fonts/gotham/anyconvcom__gothammedium-webfont.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/anyconvcom__gothammediumitalic-webfont.woff2")
      format("woff2"),
    url("/assets/fonts/gotham/anyconvcom__gothammediumitalic-webfont.woff")
      format("woff");
  font-weight: 500;
  font-style: italic;
}
