@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    src: local('Playfair Display'), url('/assets/fonts/playfair-display/PlayfairDisplay-Regular.woff') format('woff');
}
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    src: local('Playfair Display'), url('/assets/fonts/playfair-display/PlayfairDisplay[wght].woff') format('woff');
}
@font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 400;
    src: local('Playfair Display'), url('/assets/fonts/playfair-display/PlayfairDisplay-Italic.woff') format('woff');
}
@font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 400;
    src: local('Playfair Display'), url('/assets/fonts/playfair-display/PlayfairDisplay-Italic[wght].woff') format('woff');
}
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    src: local('Playfair Display'), url('/assets/fonts/playfair-display/PlayfairDisplay-Bold.woff') format('woff');
}
@font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 700;
    src: local('Playfair Display'), url('/assets/fonts/playfair-display/PlayfairDisplay-BoldItalic.woff') format('woff');
}
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 900;
    src: local('Playfair Display'), url('/assets/fonts/playfair-display/PlayfairDisplay-Black.woff') format('woff');
}
@font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 900;
    src: local('Playfair Display'), url('/assets/fonts/playfair-display/PlayfairDisplay-BlackItalic.woff') format('woff');
}

