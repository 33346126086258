@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 400;
  src: local("Proxima Nova"),
    url("/assets/fonts/proxima-nova/proxima_nova_regular_italic.woff")
      format("woff");
}
@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 400;
  src: local("Proxima Nova"),
    url("/assets/fonts/proxima-nova/proxima_nova_regular.woff")
      format("woff");
}
@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 250;
  src: local("Proxima Nova"),
    url("/assets/fonts/proxima-nova/proxima_nova_thin_italic.woff")
      format("woff");
}
@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 250;
  src: local("Proxima Nova"),
    url("/assets/fonts/proxima-nova/proxima_nova_thin.woff")
      format("woff");
}
@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 300;
  src: local("Proxima Nova"),
    url("/assets/fonts/proxima-nova/proxima_nova_light.woff")
      format("woff");
}
@font-face {
  font-family: proxima nova;
  font-style: italic;
  font-weight: 300;
  src: local("Proxima Nova"),
    url("/assets/fonts/proxima-nova/proxima_nova_light_italic.woff")
      format("woff");
}
@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 600;
  src: local("Proxima Nova"),
    url("/assets/fonts/proxima-nova/proxima_nova_semibold_italic.woff")
      format("woff");
}
@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 600;
  src: local("Proxima Nova"),
    url("/assets/fonts/proxima-nova/proxima_nova_semibold.woff")
      format("woff");
}
@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 700;
  src: local("Proxima Nova"),
    url("/assets/fonts/proxima-nova/proxima_nova_bold_italic.woff")
      format("woff");
}
@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 700;
  src: local("Proxima Nova"),
    url("/assets/fonts/proxima-nova/proxima_nova_bold.woff")
      format("woff");
}
@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 800;
  src: local("Proxima Nova"),
    url("/assets/fonts/proxima-nova/proxima_nova_extrabold_italic.woff")
      format("woff");
}
@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 800;
  src: local("Proxima Nova"),
    url("/assets/fonts/proxima-nova/proxima_nova_extrabold.woff")
      format("woff");
}
@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 900;
  src: local("Proxima Nova"),
    url("/assets/fonts/proxima-nova/proxima_nova_black_italic.woff")
      format("woff");
}
@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 900;
  src: local("Proxima Nova"),
    url("/assets/fonts/proxima-nova/proxima_nova_black.woff")
      format("woff");
}
