.cdk-global-overlay-wrapper {
  align-items: flex-end !important;
}

.cdk-overlay-pane {
  max-width: 100vw !important;
}

.mat-mdc-dialog-surface {
  border-radius: 78px 78px 0px 0px !important;
  overflow: hidden !important;
}

mat-dialog-container .mdc-dialog__container {
  transform: translate3d(0, 100%, 0);
}

.ksk-modal-wrapper {
  padding: 165px 165px 50px;
  width: 100vw;
  position: relative;

  .ksk-modal-close-button {
    position: absolute;
    border: solid;
    border-width: 0px;
    top: 30px;
    right: 50px;
    border-radius: 50%;
    font-size: 50px;
    background: #e2eded;
    width: 75px;
    height: 75px;
    text-align: center;
    line-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '✖';
    }
  }

  .ksk-modal-close {
    position: absolute;
    width: 100px;
    height: 10px;
    left: 50%;
    top: 50px;
    transform: translate3d(-50%, 0, 0);
    background: #d9d9d9;
    border-radius: 10px;
  }
}

.profile-dialog {
  .content {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(50vh - 5.2085vh);
    padding: 2vh 8vw 0;
    overflow: hidden;

    .one-tab {
      .mat-mdc-tab-header {
        display: none;
      }

      .mat-mdc-tab-body-wrapper {
        margin: 3vh 0 0;
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header-title-back {
        display: flex;
        align-items: center;
        height: 110px;
        border: 4px solid #d6d6d6;
        background: hsla(0, 0%, 100%, 0.5);
        border-radius: 20px;
        white-space: nowrap;
        padding: 0 25px;
        margin: 0 50px 0 0;
      }

      .header-title {
        display: flex;
        align-items: center;
        color: #303030;
        font-family: Inter;
        font-weight: 700;
        font-size: 72px;
        text-transform: none;
        margin: 0;
      }

      .header-btn {
        display: flex;
        align-items: center;
        height: 110px;
        padding: 0 45px;
        border: 4px solid #d6d6d6;
        background: hsla(0, 0%, 100%, 0.5);
        border-radius: 20px;
        white-space: nowrap;

        >img {
          width: 42px;
          margin: 0 40px 0 0;
        }

        >span {
          color: #293b55;
          font-family: Graphik;
          font-weight: 500;
          font-size: 48px;
          line-height: 3.5rem;
        }
      }
    }
  }

  .main {
    margin: 3vh 0 0;

    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 -25px;
    }

    *[class^="col-"] {
      display: flex;
      flex-direction: column;
      padding: 0 25px;
    }

    .col-12 {
      width: 100%;
    }

    .col-6 {
      width: 50%;
    }

    .col-4 {
      width: calc(100% / 3);
    }

    .input {
      height: 90px;
      background: #efefef;
      border: 2px solid #d9d9d9;
      border-radius: 16px;
      font-size: 34px;
      padding: 0 40px;
      outline: none !important;
      margin-bottom: 50px;
    }

    .label {
      font-size: 28px;
      margin: 0 0 5px;
    }

    kiosk-keyboard {
      left: calc(135px / 2);
      bottom: 340px;
    }
  }

  .footer {
    position: absolute;
    bottom: 50px;
    width: calc(100% - 350px);
    display: flex;
    justify-content: space-between;
    gap: 50px;

    >button {
      height: 110px;
      width: 100%;
      font-size: 40px;
      font-family: Inter;
      font-weight: 500;
      outline: none !important;
      border: none;
      background: #d9d9d9;

      &:first-child {
        background: #2e9c99;
        color: white;
      }
    }
  }
}

.view-dialog {
  .mdc-dialog__surface {
    box-shadow: none !important;
  }
}

@media (max-width: 1200px) {
  .mat-mdc-dialog-surface {
    border-radius: 39px 39px 0px 0px !important;
  }

  .ksk-modal-wrapper {
    padding: 82.5px 82.5px 25px;

    .ksk-modal-close {
      width: 50px;
      height: 5px;
      left: 50%;
      top: 25px;
      border-radius: 5px;
    }

    .ksk-modal-close-button {
      top: 15px;
      right: 25px;
      font-size: 25px;
      width: 37.5px;
      height: 37.5px;
    }
  }

  .profile-dialog {
    .content {
      height: calc(50vh - 5.2085vh);
      padding: 2vh 8vw 0;
      background-repeat: no-repeat;
      background-size: cover;

      .header {
        .header-title-back {
          height: 55px;
          border: 2px solid #d6d6d6;
          border-radius: 10px;
          padding: 0 12.5px;
          margin: 0 25px 0 0;

          img {
            height: 17px;
          }
        }

        .header-title {
          font-size: 36px;
        }

        .header-btn {
          height: 55px;
          padding: 0 22.5px;
          border: 2px solid #d6d6d6;
          border-radius: 10px;

          >img {
            width: 21px;
            margin: 0 20px 0 0;
          }

          >span {
            font-size: 24px;
          }
        }
      }
    }

    .main {
      .row {
        margin: 0 -12.5px;
      }

      *[class^="col-"] {
        padding: 0 12.5px;
      }

      .input {
        height: 45px;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        font-size: 17px;
        padding: 0 20px;
        margin-bottom: 25px;
      }

      .label {
        font-size: 14px;
        margin: 0 0 2.5px;
      }

      kiosk-keyboard {
        left: calc(67.5px / 2);
        bottom: 170px;
      }
    }

    .footer {
      bottom: 25px;
      width: calc(100% - 175px);
      gap: 25px;

      >button {
        height: 55px;
        font-size: 20px;
      }
    }
  }

  .view-dialog {
    .mdc-dialog__surface {
      box-shadow: none !important;
    }
  }
}