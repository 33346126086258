.mat-tab-body.mat-tab-body-active {
  animation: fade 0.5s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
