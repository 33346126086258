@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikSuper.ttf")
    format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikSuperItalic.ttf")
    format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikBlack.ttf")
    format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikBlackItalic.ttf")
    format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikBold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikBoldItalic.ttf")
    format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikSemibold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikSemiboldItalic.ttf")
    format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikMedium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikMediumItalic.ttf")
    format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikRegular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikRegularItalic.ttf")
    format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikLight.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikLightItalic.ttf")
    format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikExtralight.ttf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikExtralightItalic.ttf")
    format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikThin.ttf")
    format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/graphik/GraphikThinItalic.ttf")
    format("truetype");
  font-weight: 100;
  font-style: italic;
}
