@import '~normalize.css';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import 'swiper/scss';
@import 'swiper/scss/autoplay';
@import 'simple-keyboard/build/css/index.css';

@import './styles/fonts/font-graphik.scss';
@import './styles/fonts/font-gotham.scss';
@import './styles/fonts/font-proxima-nova-2.scss';
@import './styles/fonts/font-garamond.scss';
@import './styles/fonts/font-campton.scss';
@import './styles/fonts/font-din-condensed.scss';
@import './styles/fonts/font-playfair-display.scss';

@import './styles/mat-tabs.scss';
@import './styles/mat-dialog.scss';
@import './styles/mat-icons.scss';
@import './styles/mat-snackbar.scss';
@import './styles/swiper.scss';
@import './styles/mat-winloss.scss';

img {
    pointer-events: none;
}

iframe img {
    pointer-events: auto;
}

p {
    margin: 0
}

* {
    box-sizing: border-box;
}