@font-face {
  font-family: campton;
  font-style: normal;
  font-weight: 400;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonBook.woff") format("woff");
}
@font-face {
  font-family: campton;
  font-style: italic;
  font-weight: 400;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonBookItalic.woff")
      format("woff");
}
@font-face {
  font-family: campton;
  font-style: normal;
  font-weight: 200;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonThin.woff") format("woff");
}
@font-face {
  font-family: campton;
  font-style: italic;
  font-weight: 200;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonThinItalic.woff")
      format("woff");
}
@font-face {
  font-family: campton;
  font-style: normal;
  font-weight: 250;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonExtraLight.woff")
      format("woff");
}
@font-face {
  font-family: campton;
  font-style: italic;
  font-weight: 250;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonExtraLightItalic.woff")
      format("woff");
}
@font-face {
  font-family: campton;
  font-style: normal;
  font-weight: 300;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonLight.woff") format("woff");
}
@font-face {
  font-family: campton;
  font-style: italic;
  font-weight: 300;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonLightItalic.woff")
      format("woff");
}
@font-face {
  font-family: campton;
  font-style: normal;
  font-weight: 500;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonMedium.woff") format("woff");
}
@font-face {
  font-family: campton;
  font-style: italic;
  font-weight: 505;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonMediumItalic.woff")
      format("woff");
}
@font-face {
  font-family: campton;
  font-style: normal;
  font-weight: 600;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonSemiBold.woff") format("woff");
}
@font-face {
  font-family: campton;
  font-style: italic;
  font-weight: 600;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonSemiBoldItalic.woff")
      format("woff");
}
@font-face {
  font-family: campton;
  font-style: normal;
  font-weight: 700;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonBold.woff") format("woff");
}
@font-face {
  font-family: campton;
  font-style: italic;
  font-weight: 700;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonBoldItalic.woff")
      format("woff");
}
@font-face {
  font-family: campton;
  font-style: normal;
  font-weight: 800;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonExtraBold.woff") format("woff");
}
@font-face {
  font-family: campton;
  font-style: italic;
  font-weight: 800;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonExtraBoldItalic.woff")
      format("woff");
}
@font-face {
  font-family: campton;
  font-style: normal;
  font-weight: 900;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonBlack.woff") format("woff");
}
@font-face {
  font-family: campton;
  font-style: italic;
  font-weight: 900;
  src: local("Campton"),
    url("/assets/fonts/campton/CamptonBlackItalic.woff")
      format("woff");
}
