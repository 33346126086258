
.winlossContainer {
    width: 80%;
    border: 1px solid black;
    margin-bottom: 40px;
}

.select-boxes {
    width: 100%;
    min-height: 80px;
}

.mat-mdc-select-value {
    padding: 10px;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    border: inherit;
}

.winlossContainer .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    display: none !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    min-height: 80px;
    margin: 10px;
}

.mat-mdc-option.mdc-list-item {
    align-items: center;
    font-size: 50px;
    margin: 15px;
    padding: 10px;
}

.cdk-overlay-pane {
    max-height: none !important;
  }
  
.mat-primary .mat-mdc-select-panel .mdc-menu-surface {
    max-height: none !important;
  }
  
.mat-primary .mat-mdc-select-panel .mdc-menu-surface--open {
    max-height: none !important;
  }
  
.mat-mdc-select-panel .mat-option {
    white-space: normal !important;
  }

.mat-winloss-select {
    height: 100%;
}

.mat-winloss-option {
    margin-bottom: 10px;
    padding: 30px !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
    max-height: none !important;
    padding: 15px !important;
}

.mat-mdc-option .mdc-list-item__primary-text{
    font-size: 60px;
}

/* Full Select Box */
.mat-primary.mdc-menu-surface {
    box-shadow:  0px 5px 5px -3px rgb(0, 0, 0.2),
                 0px 8px 10px 1px rgb(0, 0, 0.14),
                  0px 3px 14px 2px rgb(0, 0, 0.12);
    background-color: var(--mdc-theme-surface, rgb(230, 240, 207));
    color: var(--mdc-theme-on-surface, rgb(83, 84, 4));
    // background-color: transparent;
    // color: black ;
}

/* Select Box item color */
.mat-primary .mdc-list-item__primary-text {
    //color: var(--mdc-theme-text-primary-on-background, rgb(41, 7, 167));
    color: black ;
}

/* Select Box items  hover and focused*/
.mat-primary .mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-primary .mat-mdc-option:focus:not(.mdc-list-item--disabled) {
    background-color: transparent;
}

/* Select Box items Selected  - user selected and then look up again background*/
.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
    background-color: transparent;
   //background-color: rgb(146, 139, 245) ;
}

/* Select Box items Selected Text*/
.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: black ;
}

@media (max-width: 1200px) {
    .winlossContainer {
        margin-bottom: 20px;
    }
   
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
        min-height: 25px;
        margin: 0px;
    }

    .mat-mdc-option.mdc-list-item {
        font-size: 20px;
        margin: 5px;
        padding: 5px;
    }

    .mat-winloss-option {
        padding: 10px !important;
    }
    
    .mdc-menu-surface.mat-mdc-select-panel {
        padding: 5px !important;
    }

    .mat-mdc-option .mdc-list-item__primary-text{
        font-size: 30px !important;
    }
}
