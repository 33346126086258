.cdk-overlay-container {
    .mat-mdc-snack-bar-container {
        margin: 0 60px 40px 0 !important;
    }

    .mdc-snackbar__surface {
        border: 1px solid #2e9c99 !important;
        background: white !important;
        border-radius: 16px !important;
        padding: 40px !important;
        box-shadow: none !important;
    }

    .mat-mdc-snack-bar-label {
        font-size: 34px !important;
        color: #2e9c99 !important;
        line-height: inherit !important;
    }
}

@media (max-width: 1200px) {
    .cdk-overlay-container {
        .mat-mdc-snack-bar-container {
            margin: 0 30px 20px 0 !important;
        }
        .mdc-snackbar__surface {
            border-radius: 8px !important;
            padding: 20px !important;
        }

        .mat-mdc-snack-bar-label {
            font-size: 17px !important;
        }
    }
}
